import Announcement from '../Utils/Announcement'
import NavBar from '../components/NavBar' 
import HeroSection from '../components/HeroSection'
import About from '../components/About'
import Prodcuts from '../components/Products'
import IndustriesPage from './IndustriesPage'
import CaseStudies from './CaseStudies'
import ResourcesPage from './ResourcesPage'
import Footer from '../components/Footer'


function HomePage() {
  return (
  <div>
    <Announcement />
    <section>
  	{/* navbar */}
      <NavBar />
      <HeroSection />
      <section id="Products">
      <Prodcuts />
      </section>
      <section id="Industries">
      <IndustriesPage />
      </section>
      <section id="About">
      <About />
      </section>
      <section id="CaseStudies">
      <CaseStudies />
      </section>
      <section id="ResourcesPage">
        <ResourcesPage />
      </section>
      <Footer />
		</section>
  </div>
  )
}
export default HomePage