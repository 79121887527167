import Button from "./Button"
import Input from "./Input"
import emailjs from 'emailjs-com';
import { useState } from "react";
import Alert from "../Utils/Alert";


function Form() {

  const [email , setEmail] = useState('')
  const [name, setName] = useState('')
  const [produit , setProduit] = useState('')
  const [message , setMessage] = useState('')
  const [succes, setSucces] = useState(true)


  const handleEmail = (e) => {
    setEmail(e.target.value)
  }
  const handleName = (e) => {
    setName(e.target.value)
  }
  const handleProduit = (e) => {
    setProduit(e.target.value)
  }
  const handleMessage = (e) => {
    setMessage(e.target.value)
  }

  
function sendEmail(e) {
    e.preventDefault();
    emailjs.send("service_ahkdmjr","template_8n1517q",{email: email,name: name,produit: produit,message: message},'w7HnPO6tPzu8VO1GG')
    .then((result) => {
      console.log(result.text)
      setSucces(false)
      setTimeout(() => {
        setSucces(true)
      }, 2000);
      e.target.reset()
    }, (error) => {
      console.log(error.text);
    })};
  

  return (
    <>
    <form onSubmit={sendEmail} className="space-y-4">
    {!succes && <Alert />}
      <label for="produit" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select an option</label>
      <select onChange={handleProduit} id="produit" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
        <option name="select-input" id="select-input" disabled selected>Choose Product</option>
        <option value="License plate recognition">License Plate Recognition</option>
        <option value="Face Recognition">Face Recognition</option>
        <option value="Custom Object Detection">Custom Object detection</option>
      </select>

      <div>
        <Input name="name" onChange={handleName} className="w-full rounded-lg  p-3 text-sm border-2  border-l-green-500" placeholder="Name Company" type="text" />
      </div>

      <div>
        <Input onChange={handleEmail} name="email" id="email" className="w-full rounded-lg  p-3 text-sm border-2  border-l-green-500" placeholder="Email Company" type="text" />
      </div>

      <div>
        <textarea onChange={handleMessage} name="message" id="message" className="w-full rounded-lg  border-2  border-l-green-500 p-3 text-sm" placeholder="Message" rows={8} defaultValue={""} />
      </div>

      <div className="mt-4">
        <Button  type="submit" className="inline-block w-full rounded-lg bg-[#4EFAAE] px-5 py-3 font-medium text-black sm:w-auto" content="Send Message" />
      </div>
    </form>
     </>
  )
}

export default Form
