import React from "react";
import logo from '../Assets/Images/logo.png'
import { Link } from "react-router-dom";
import { useState } from "react";

function NavbarC() {

  return (
    <nav className="bg-[#212035] shadow">
      <div className="container mx-auto  py-3 md:flex md:justify-between md:items-center lg:customPadding">
        <div className="flex justify-between items-center">
          <div>
            <Link
              className="text-gray-800 text-xl font-bold md:text-2xl hover:text-gray-700"
              to="/"
            >
              <img className="W-Custom" src={logo} />
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavbarC;