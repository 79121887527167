import './index.css'
import { BrowserRouter , Routes , Route } from 'react-router-dom'
import ContactUsPage from './pages/ContactUsPage'
import HomePage from './pages/HomePage'
import LicensePlateTest from './pages/LicensePlateTest'


function App() {
  return (
    <div className="App">
        <BrowserRouter>
            <Routes>
               <Route path='/' element={<HomePage />} />
               <Route path='/ContactUsPage' element={<ContactUsPage />} />
               <Route path='/licenseplatetest' element={<LicensePlateTest />} />
            </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
