import React from 'react'
import Announcement from '../Utils/Announcement'
import NavbarC from '../components/NavbarC'
import Footer from '../components/Footer'
import UploadImage from '../components/uploadImage'

function LicensePlateTest() {
    return (
        <div className='h-100 w-100 bg-black'>
            <Announcement />
            <NavbarC />
            <section className="bg-[#212035]">
                <div className="mx-auto max-w-screen-xl px-4 mb-10 py-16 sm:px-6 lg:px-8" style={{ height: '793px' }}>
                    <div className="grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-5">
                        <div className="lg:col-span-2 lg:py-12">
                            <h2 class=" text-center text-1xl mb-5  font-bold sm:text-4xl  text-white lg:text-start lg:mb-0 lg:leading-tight lg:text-3xl		">Welcome to our license plate recognition testing page! Here you can upload an image of a license plate and test our license plate recognition system. Our system uses advanced image recognition algorithms to detect and recognize license plates in images.</h2>
                            <p className=" hidden text-light text-center mt-4 md:block lg:block text-gray-200 lg:text-start">
                            To get started, simply upload an image of a license plate and click on the 'submit' button. Our system will process the image and return the recognized license plate number as a string. Please note that the accuracy of our system may vary depending on the quality of the image and other factors such as lighting, angle, and distance.</p>
                        </div>
                        <div className="rounded-lg bg-white shadow-lg lg:col-span-3 lg:p-12">
                            <UploadImage />
                        </div>
                    </div>
                </div>
                <div style={{ marginTop:'8rem'}}>
                <Footer />
                </div>
            </section>
        </div>
    )
}

export default LicensePlateTest
