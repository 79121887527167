import React from 'react'

function Announcement() {
  return (
    <div className="bg-[#4EFAAE] px-4 py-3 text-white">
    <p class="text-center text-sm font-medium text-black">
        the company as a frist computer vision company in morocco
    </p>
  </div>
  )
}

export default Announcement