import React from 'react'
import Eye from '../Assets/Images/eye.png'
import Help from '../Assets/Images/books.PNG'
import '../App.css'

function ResourcesPage() {
  return (
    <>
    
      <div className=" mx-auto sm:p-10 md:p-16 bg-[#212035] lg:padd ">
      <div className='title mb-11'>
                <p className='text-white titles text-2xl lg:text-5xl  text-center py-7 '>Resources</p>
            </div>
      <div className='flex flex-col  items-center justify-between lg:flex-row lg:justify-evenly lg:items-center  '>
        <div className=" my-2 flex flex-col hei  overflow-hidden rounded lg:my-5">
          <img src={Eye} alt="" className="w-full h-60 sm:h-96 dark:bg-gray-500" />
          <div className="p-6 pb-12 m-4 mx-auto -mt-16 space-y-6 lg:hei sm:px-10 sm:mx-12 lg:rounded-md bg-gray-900">
            <div className="space-y-2">
              <h3 rel="noopener noreferrer" className="inline-block text-white  ">Our blog features articles on the latest trends in computer vision and recognition technology, as well as Neuras' research and development efforts.</h3>
                <small className="text-xs hover:underline text-[#4EFAAE]">Neuras.Ai</small>
            </div>
          </div>
        </div>

        <div className=" my-2 flex flex-col hei max-w-3xl overflow-hidden rounded lg:my-5">
          <img src={Help} alt="" className="w-full h-60 sm:h-96 dark:bg-gray-500" />
          <div className="p-6 pb-12 m-4 mx-auto -mt-16 space-y-6 lg:hei sm:px-10 sm:mx-12 lg:rounded-md bg-gray-900">
            <div className="space-y-2">
              <h3 rel="noopener noreferrer" className="inline-block text-white ">We also offer white papers and e-books on related topics, providing valuable resources to help businesses stay informed about the latest developments in the field</h3>
                <small className="text-xs hover:underline text-[#4EFAAE] ">Neuras.Ai</small>
            </div>
          </div>
        </div>
        </div>
        </div>
    </>
  )
}

export default ResourcesPage