import React from 'react'
import '../App.css'
import STM from '../Assets/Images/stm.PNG'
import Team from '../Assets/Images/team.PNG'
import Client from '../Assets/Images/client.PNG'

function About() {
    return (
        <div className='px-5  bg-[#212035] py-11 md:px-5 lg:px-5' >
            <div className='title mb-11 relative'>
                {/* <img src={Design} className="absolute aboutus" alt="" /> */}
                <p className='text-white titles text-2xl lg:text-5xl mb-11  text-center my-14 '> About Us</p>
            </div>
            <section className="dark:bg-gray-800 dark:text-gray-100">
                <div class="flex  items-center justify-center  bg-[#212035]">
                    <div class="container grid max-w-screen-xl gap-8 lg:grid-cols-2 lg:grid-rows-2">
                        <div class="row-span-2 flex flex-col rounded-md border border-[#4EFAAE]">
                            <div class="h-1/2 flex-1">
                                <img src={STM} class="w-full object-cover object-right-top" alt="omnichannel" /></div>
                            <div class="p-10">
                                <p class="mt-2 text-white"> <span className='italic text-4xl mr-3 text-[#4EFAAE] decoration-solid'>1</span>Neuras is a subsidiary of Societe Tangeroise de Maintenance, a leading maintenance and engineering services provider in Morocco with over 25 years of experience.</p>
                            </div>
                        </div>
                        <div class="flex rounded-md border border-[#4EFAAE]">
                            <div class="flex-1 p-10">
                                <p class="mt-2 text-white"> <span className='italic text-4xl mr-3 text-[#4EFAAE] decoration-solid'>2</span>Our team includes experts in computer vision, artificial intelligence, and recognition technology, who are committed to delivering innovative solutions that meet our clients' needs.</p>
                            </div>

                            <div class="relative hidden h-full w-1/3 overflow-hidden lg:block">
                                <div class="absolute inset-0">
                                    <img src={Team} class="h-full w-full object-cover object-left-top" alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="flex rounded-md border border-[#4EFAAE]">
                            <div class="flex-1 p-10">
                                <p class="mt-2 text-white"> <span className='italic text-4xl mr-3 text-[#4EFAAE] decoration-solid'>3</span>Our core values of transparency, integrity, and customer satisfaction guide our work, and we strive to uphold them in all our dealings.</p>
                            </div>

                            <div class="relative hidden h-full w-1/3 overflow-hidden lg:block">
                                <div class="absolute inset-0">
                                    <img src={Client} class="h-full w-full object-cover object-left-top" alt="" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    )
}

export default About
