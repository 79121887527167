import React from 'react'
import Announcement from '../Utils/Announcement'
import Form from '../components/Form'
import Footer from '../components/Footer'
import NavbarC from '../components/NavbarC'

function ContactUsPage() {
  return (
    <>
      <Announcement />
      <NavbarC />
      <section className="bg-[#212035]">
        <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8" style={{ height: '793px' }}>
          <div className="grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-5">
            <div className="lg:col-span-2 lg:py-12">
              <h2 class=" text-center text-1xl mb-5  font-bold sm:text-4xl  text-white lg:text-start lg:mb-0 lg:leading-tight lg:text-3xl		">If you have any questions or would like to learn more about our solutions, please fill out our contact form or reach out to our sales and support teams.</h2>
              <p className="hidden text-center mt-4 lg:block text-gray-400 lg:text-start">
                As a leading Moroccan company, we serve clients throughout Morocco and beyond. Contact us today to see how we can help your business succeed.        </p>
              <div className="mt-8 text-center lg:text-start">
                <a href className="  text-2xl font-bold text-[#4EFAAE]">
                  neuras@stm.ma
                </a>
              </div>
            </div>
            <div className="rounded-lg bg-white p-8 shadow-lg lg:col-span-3 lg:p-12">
              <Form />
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  )
}

export default ContactUsPage