import React from 'react'

function Card(props) {
  return (
    <>
      <div className={props.className}>
			<img src={props.image} alt="" className="h-80 dark:bg-gray-500 rounded-lg aspect-video" />
			<div className="flex flex-col justify-center flex-1 p-6 dark:bg-gray-900">
				<span className=" mb-2 text-xs uppercase text-white italic lg:mb-5 underline decoration-1">{props.name}</span>
				<h3 className="text-2xl font-bold text-[#4EFAAE] leading-9">{props.title}</h3>
				<p className="my-6 text-white leading-9 text-sm	">{props.contenu}</p>
				<div className='flex  md:flex-row flex-col lg:flex-row'>
					<button className='btn bg-[#4EFAAE] py-3 px-7 rounded-lg'>{props.btn}</button>
					<button onClick={props.onClick} className={props.class}>{props.btn2}</button>
				</div>
			</div>
		</div>
    </>
  )
}

export default Card
