import '../App.css'
import logo from '../Assets/Images/logo.png'
import { Link } from "react-router-dom";
import { useState } from "react";

function Navbar() {

    const [isOpen , setIsOpen] = useState(false)
    const toggleDropdown = () => {
      setIsOpen(!isOpen)
    }
  
  return (
    <nav className="bg-[#212035] shadow ">
      <div className="container mx-auto   py-3 md:flex md:justify-between md:items-center lg:customPadding">
        <div className="flex justify-between items-center ">
          <div>
            <Link
              className="text-gray-800 text-xl font-bold md:text-2xl hover:text-gray-700"
              to="/"
            >
              <img className="W-Custom" src={logo} />
            </Link>
          </div>

          <div className="Dropdown flex md:hidden " style={{margin:' 0 20px'}}>
            <button
              onClick={toggleDropdown}
              type="button"
              className="text-gray-500 hover:text-gray-600 focus:outline-none focus:text-gray-600"
              aria-label="toggle menu"
            >
              <svg viewBox="0 0 24 24" className="h-6 w-6 fill-current">
                <path
                  fillRule="evenodd"
                  d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                ></path>
              </svg>
            </button>
          </div>
        </div>

        {!isOpen && (


        <div className="hidden items-center md:block  lg:block">
          <div className="customNavBar dropdown-content flex flex-col md:flex-row md:mx-6 lg:customPadding">
             <a
              className="my-1 text-sm text-white font-medium hover:text-[#4EFAAE] md:mx-4 md:my-0"
              href="#Products"
            >
              Products 
            </a>
            <a
              className="my-1 text-sm text-white font-medium hover:text-[#4EFAAE] md:mx-4 md:my-0"
              href="#Industries"
            >
              Industries 
            </a>
            <a
              className="my-1 text-sm text-white font-medium hover:text-[#4EFAAE] md:mx-4 md:my-0"
              href="#About"
            >
              About Us
            </a>
           
           
            <a
              className="my-1 text-sm text-white font-medium hover:text-[#4EFAAE] md:mx-4 md:my-0"
              href="#CaseStudies"
            >
              Case Studies 
            </a>
            <a
              className="my-1 text-sm text-white font-medium hover:text-[#4EFAAE] md:mx-4 md:my-0"
              href="#ResourcesPage"
            >
              Resources
            </a>
          </div>
        </div>
        )}
          

          {isOpen && (
        <div className="md:flex items-center animate__animated  animate__fadeInDownBig">
          <div className="   customNavBar dropdown-content flex flex-col md:flex-row md:mx-6 lg:customPadding">
            <a
              className="my-3 text-sm text-white font-medium hover:text-[#4EFAAE] md:mx-4 md:my-0"
              href="#Products"
            >
              Products 
            </a>
            <a
              className="my-3 text-sm text-white font-medium hover:text-[#4EFAAE] md:mx-4 md:my-0"
              href="#Industries"
            >
              Industries 
            </a>
            <a
              className="my-3 text-sm text-white font-medium hover:text-[#4EFAAE] md:mx-4 md:my-0  "
              href="#About"
            >
              About Us
            </a>
            <a
              className="my-3 text-sm text-white font-medium hover:text-[#4EFAAE] md:mx-4 md:my-0"
              href="#CaseStudies"
            >
              Case Studies 
            </a>
            <a
              className="my-3 text-sm text-white font-medium hover:text-[#4EFAAE] md:mx-4 md:my-0"
              href="#ResourcesPage"
            >
              Resources
            </a>
          </div>
        </div>
          )}
      </div>
    </nav>
  );
}

export default Navbar;