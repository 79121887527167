import React from 'react'
import FaceReco from '../Assets/Images/facereco.png'
import LicensePlate from '../Assets/Images/test1.png'
import Face from '../Assets/Images/face.png'
import Card from './Card'
import { useNavigate } from 'react-router-dom'

function Products() {

    const navigate = useNavigate()
    const licenseplatenav = () => {
        navigate('/licenseplatetest')
    }

    return (
        <section className=" bg-[#212035] p-4 lg:p-8 dark:bg-gray-800 dark:text-gray-100 ">
            <div className='title mb-11'>
                <p className='text-white titles text-2xl lg:text-5xl  text-center py-7 '>Products</p>
            </div>
            <div className="container mx-auto space-y-12 lg:max-w-screen-xl	">
                <Card 
                image={LicensePlate}
                className="flex flex-col overflow-hidden rounded-md shadow-sm lg:flex-row  lg:my-17"
                name="Neuras"
                title="Automated Biometric Time Management with ALPR, Access Control, and Facial Recognition ."
                contenu="Our products and services include an automatic license plate recognition system, access control solutions, and time management using face recognition biometrics."
                btn="Contact Us"
                btn2="Test License Plate Recognition"
                class="text-white border-2 border-[#4EFAAE] mt-4 rounded-lg px-5 py-3 ml-3 lg:mt-0"
                onClick={licenseplatenav}
                />
                <Card
                    image={FaceReco}
                    className="flex flex-col overflow-hidden rounded-md shadow-sm lg:flex-row-reverse lg:my-11"
                    name="Neuras"
                    title="Advantages of ALPR for Transportation, Logistics, and Security."
                    contenu="Our automatic license plate recognition system is ideal for businesses in the transportation, logistics, and security industries, as it provides accurate and reliable recognition technology that can identify license plates in real-time."
                    btn="Contact Us"
                />
                <Card
                    image={Face}
                    className="flex flex-col overflow-hidden rounded-md shadow-sm lg:flex-row lg:my-11"
                    name="Neuras"
                    title="Secure and Efficient Business Operations with Face Recognition Biometrics"
                    contenu="Our access control solutions and time management using face recognition biometrics help businesses improve their security and streamline their HR processes, reducing administrative tasks and enhancing operational efficiency."
                    btn="Contact Us"
                />
            </div>
        </section>
    )
}

export default Products
