import React from 'react'

function CaseStudies() {
  return (
    <>
      <section className="py-6 bg-[#212035]">
      <div className='title mb-11'>
                <p className='text-white titles text-2xl lg:text-5xl  text-center py-7 '>Case Studies</p>
            </div>
        <div className="container mx-auto flex flex-col items-center  justify-between max-w-lg p-4  sm:p-10 lg:flex-row lg:max-w-screen-xl">
          <div className=" my-5 flex flex-col items-center justify-center flex-1 p-4 pb-8 sm:p-8 lg:p-16 dark:bg-gray-900 border-2 border-[#4EFAAE] lg:my-0  lg:mr-4">
            <p className="text-5xl font-bold text-center text-[#4EFAAE] lg:my-3">1</p>
            <p className="font-semibold text-center text-white">Our case studies showcase how our solutions have helped businesses across various industries improve their operations and security.</p>
          </div>
          <div className="flex flex-col items-center justify-center flex-1 p-4 pb-8 text-center rounded-md sm:p-8 lg:p-16 dark:bg-violet-400 dark:text-gray-900 border-2 border-[#4EFAAE]">
            <p className="text-5xl font-bold text-[#4EFAAE] lg:my-3">2</p>
            <p className="font-semibold text-white">By highlighting the challenges our clients faced and the solutions we provided, we can demonstrate our expertise and capabilities to potential customers.</p>
          </div>
        </div>
      </section>
    </>
  )
}

export default CaseStudies