import Industries from '../Assets/Images/industries.png';
import 'animate.css';


function IndustriesPage() {

  return (
    <>
      <>
        <section className="bg-[#212035]">
          <div className="container max-w-xl p-6 pt-12 mx-auto  lg:px-8 lg:max-w-7xl">
            <div className='title mb-7'>
              <p className='text-white titles text-2xl lg:text-5xl  text-center py-7 animate__zoomIn'>Industries</p>
            </div>
            <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
              <div>
                <h3 className="text-2xl font-bold tracking-tight sm:text-3xl text-white lg:text-4xl">Customized Solutions for Transportation, Logistics, and Security Industries</h3>
                <div className="mt-12 space-y-12">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center w-12 h-12 rounded-md bg-[#4EFAAE]">
                        <svg  color='white' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                        </svg>
                      </div>
                    </div>
                    <div className="ml-4">
                      <p className="mt-2 text-white">We work with businesses across various industries, including transportation, logistics, and security, providing them with customized solutions that meet their unique needs.</p>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center w-12 h-12 rounded-md bg-[#4EFAAE]">
                        <svg  color='white' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                        </svg>
                      </div>
                    </div>
                    <div className="ml-4">
                      <p className="mt-2 text-white">Our solutions have helped businesses improve their operations and security by providing accurate and reliable recognition technology that can help identify vehicles, people, and objects in real-time.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div aria-hidden="true" className="mt-10 lg:mt-0">
                <img src={Industries} alt="" className="mx-auto rounded-lg shadow-lg dark:bg-gray-500" />
              </div>
            </div>
            <div>
            </div>
          </div>
        </section>
      </>
    </>
  )
}

export default IndustriesPage