import React from 'react'
import Logo from '../Assets/Images/logo.png'

function Footer() {
  return (
    <>
      <footer className="px-4 py-8 bg-gray-800  ">
	<div className="container flex flex-wrap items-center justify-center mx-auto space-y-4 sm:justify-between sm:space-y-0">
		<div className="flex flex-row pr-3 space-x-4 sm:space-x-8">
			<div className="flex items-center justify-center flex-shrink-0 w-12 h-12 rounded-full dark:bg-violet-400">
				<img src={Logo} alt="" />
			</div>
			<ul className="flex flex-wrap items-center space-x-4 sm:space-x-8">
				<li>
					<p className=" text-white" >Terms of Use</p>
				</li>
				<li>
					<p className=" text-white" >Privacy</p>
				</li>
			</ul>
		</div>
		<ul className="flex flex-wrap pl-3 space-x-4 sm:space-x-8">
			<li>
            <a href className="  text-2xl font-bold text-[#4EFAAE]">
                  neuras@stm.ma
                </a>
			</li>
		</ul>
	</div>
</footer>
    </>
  )
}

export default Footer
