import React from 'react'

function Input(props) {
  return (
    <>
    <input 
    className={props.className}
    placeholder={props.placeholder} 
    type={props.type}
    value={props.value}
    onChange={props.onChange}
    id={props.id}
     />
    </>
  )
}

export default Input
