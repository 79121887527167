import React, { useState } from "react";
import axios from "axios";
import Replay from '../Assets/Images/replay.png'
import Spinner from '../Utils/Spinner'


function UploadImage() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [res, setRes] = useState('')
  const [Loading , setLoading] = useState(false)

  const handleImageChange = (event) => {
    setLoading(true)
    const imageFile = event.target.files[0];
    const imageUrl = URL.createObjectURL(imageFile);
    setSelectedImage(imageUrl);

    // Send the image file to the server
    const formData = new FormData();
    formData.append('lp', imageFile);
    axios.post('http://129.151.241.27:5000/alpr', formData)
    .then((response) => {
     setTimeout(() => {
      setRes(response.data.license_plate);
       setLoading(false)
     }, 2000);
    })
     .catch((error) => {
        console.error(error);
      });
  };

  const handleImageReset = () => {
    setSelectedImage(null);
    setRes('')
  };

  return (
    <div className="">
      {selectedImage ? (
        <div className="flex  flex-col justify-center items-center lg:flex-col">
          {Loading && <Spinner />}
          {res && <span className="bg-black px-3 py-2 rounded-lg my-2 text-white">{res}</span>}
          <img src={selectedImage} alt="Selected" />
          <img className='bg-green-200 my-2 cursor-pointer p-2 rounded-lg mt-2 lg:mt-4' src={Replay} onClick={handleImageReset} alt="Replay" />
        </div>
      ) : (
        <label for="dropzone-file" class="flex flex-col items-center justify-center w-full  border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                <div class="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg aria-hidden="true" class="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                    <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span> or drag and drop</p>
                    <p class="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                </div>
                <input type="file"  id="dropzone-file" onChange={handleImageChange} class="hidden" />
            </label>
      )}
    </div>
  );
}

export default UploadImage;