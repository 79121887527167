// import { useNavigate } from 'react-router-dom'
import '../App.css'
import { Link } from 'react-router-dom'

function HeroSection(){

    return (
        <section className='bg-[#212035] text-center md:text-center lg:text-start relative ' >
            <div className="container  mx-auto flex flex-col items-center px-4 py-16 text-center md:py-32 md:px-10 lg:px-32 xl:customWidth leading-8">
                <p className=" text-lg lg:text-4xl font-bold  sm:text-5xl text-white landing  heightt ">Welcome to <span className="font-extrabold  landing text-transparent bg-clip-text bg-gradient-to-br from-[#4EFAAE] to-[#32b97d]">Neuras</span>, a leading Moroccan company that provides cutting-edge computer vision solutions to businesses across various industries, including transportation, logistics, and security.
                </p>
                <p className=" text-base px-8 mt-8 mb-12 lg:text-lg  text-gray-300">Our automatic license plate recognition system is one of the most accurate  on the market, <br /> and it can recognize license plates from more than 50 countries. Our access control solutions and time management <br /> using face recognition biometrics help businesses improve their security and <br /> streamline their operations</p>
                <div className="flex flex-wrap justify-center">
                    <Link to="/ContactUsPage">
                    <button className="px-8 py-3 m-2 text-lg font-semibold rounded bg-[#4EFAAE] dark:text-gray-900">Contact Us</button>                    
                    </Link>
                    
                    <Link to="/licenseplatetest">                    
                    <button className="px-8 py-3 m-2 text-lg text-white font-semibold rounded border-2 border-[#4EFAAE] dark:text-gray-900" >Test License Plate Tool</button>
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default HeroSection